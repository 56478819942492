import { render, staticRenderFns } from "./UNInfo.vue?vue&type=template&id=a000cd5c&scoped=true&"
import script from "./UNInfo.vue?vue&type=script&lang=js&"
export * from "./UNInfo.vue?vue&type=script&lang=js&"
import style0 from "./UNInfo.vue?vue&type=style&index=0&id=a000cd5c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a000cd5c",
  null
  
)

export default component.exports